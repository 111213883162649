//
// map.js
// Theme module
//

const maps = document.querySelectorAll("[data-map]");
const accessToken = "pk.eyJ1Ijoic2VhbnBtZ2FsbGFnaGVyIiwiYSI6ImNpaDg4ZzN5ZzB0YWR1bWtpbzN4c25rdzYifQ.WZmz44-3Tay7WBeglP7kaA";

maps.forEach((map) => {
	const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

	const defaultOptions = {
		container: map,
		style: "mapbox://styles/mapbox/light-v9",
		scrollZoom: false,
		interactive: false,
	};

	const options = {
		...defaultOptions,
		...elementOptions,
	};

	// Get access token
	mapboxgl.accessToken = accessToken;

	// Init map
	new mapboxgl.Map(options);
});
