const RATES = {
	full: 92,
	breakfast: 23,
	lunch: 26,
	dinner: 38,
	incidentals: 5,
};

const perDiemForm = document.getElementById("perDiemForm");

if (perDiemForm) {
	const form = document.getElementById("perDiemForm");
	const startDateInput = document.getElementById("startDate");
	const endDateInput = document.getElementById("endDate");
	const tableBody = document.getElementById("perDiemTableBody");
	const totalAmountSpan = document.getElementById("totalAmount");
	const calculateButton = document.getElementById("calculateTotal");
	const downloadButton = document.getElementById("downloadCSV");

	// Set default start date to today
	const today = new Date().toISOString().split("T")[0];
	startDateInput.value = today;
	endDateInput.value = today;

	// Event listeners
	startDateInput.addEventListener("change", () => {
		if (endDateInput.value < startDateInput.value) {
			endDateInput.value = startDateInput.value;
		}
		generateDayRows();
	});

	endDateInput.addEventListener("change", generateDayRows);
	calculateButton.addEventListener("click", calculateTotal);
	downloadButton.addEventListener("click", downloadCSV);

	function generateDayRows() {
		if (!startDateInput.value || !endDateInput.value) return;

		// Create dates at noon to avoid timezone issues
		const start = new Date(startDateInput.value + "T12:00:00");
		const end = new Date(endDateInput.value + "T12:00:00");
		tableBody.innerHTML = "";

		for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
			const row = createDayRow(date);
			tableBody.appendChild(row);
		}

		calculateTotal();
	}

	function createDayRow(date) {
		// Adjust for timezone to ensure consistency with date picker
		const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
		const dateStr = localDate.toLocaleDateString("en-US", {
			month: "numeric",
			day: "numeric",
			year: "numeric",
		});
		const row = document.createElement("tr");
		const dateId = localDate.toISOString().split("T")[0];

		row.innerHTML = `
      <td>${dateStr}</td>
      <td>
        <div class="checkbox-wrapper">
          <input type="checkbox" class="meal-checkbox" data-type="full" data-date="${dateId}">
        </div>
      </td>
      <td>
        <div class="checkbox-wrapper">
          <input type="checkbox" class="meal-checkbox" data-type="breakfast" data-date="${dateId}">
        </div>
      </td>
      <td>
        <div class="checkbox-wrapper">
          <input type="checkbox" class="meal-checkbox" data-type="lunch" data-date="${dateId}">
        </div>
      </td>
      <td>
        <div class="checkbox-wrapper">
          <input type="checkbox" class="meal-checkbox" data-type="dinner" data-date="${dateId}">
        </div>
      </td>
      <td>
        <div class="checkbox-wrapper">
          <input type="checkbox" class="incidentals-checkbox" data-date="${dateId}" disabled checked>
        </div>
      </td>
      <td class="daily-total">$0.00</td>
    `;

		// Add event listeners for checkboxes
		const checkboxes = row.querySelectorAll(".meal-checkbox");
		const incidentalsCheckbox = row.querySelector(".incidentals-checkbox");

		checkboxes.forEach((checkbox) => {
			checkbox.addEventListener("change", (e) => {
				const fullDayCheckbox = row.querySelector('[data-type="full"]');
				const mealCheckboxes = row.querySelectorAll('.meal-checkbox:not([data-type="full"])');

				if (e.target.dataset.type === "full") {
					mealCheckboxes.forEach((cb) => {
						cb.checked = false;
						cb.disabled = e.target.checked;
					});
				} else {
					fullDayCheckbox.checked = false;
					const hasAnyMeal = Array.from(mealCheckboxes).some((cb) => cb.checked);
					incidentalsCheckbox.checked = hasAnyMeal;
				}

				calculateDayTotal(row);
				calculateTotal();
			});
		});

		return row;
	}

	function calculateDayTotal(row) {
		let total = 0;
		const checkboxes = row.querySelectorAll(".meal-checkbox");
		const incidentalsCheckbox = row.querySelector(".incidentals-checkbox");

		checkboxes.forEach((checkbox) => {
			if (checkbox.checked) {
				if (checkbox.dataset.type === "full") {
					total = RATES.full;
				} else {
					total += RATES[checkbox.dataset.type];
				}
			}
		});

		if (incidentalsCheckbox.checked && total > 0 && !row.querySelector('[data-type="full"]').checked) {
			total += RATES.incidentals;
		}

		row.querySelector(".daily-total").textContent = `$${total.toFixed(2)}`;
		return total;
	}

	function calculateTotal() {
		const rows = tableBody.querySelectorAll("tr");
		let total = 0;

		rows.forEach((row) => {
			total += calculateDayTotal(row);
		});

		totalAmountSpan.textContent = total.toFixed(2);
		return total;
	}

	function downloadCSV() {
		const rows = [["Date", "Full Day", "Breakfast", "Lunch", "Dinner", "Incidentals", "Daily Total"]];
		const tableRows = tableBody.querySelectorAll("tr");
		let grandTotal = 0;

		tableRows.forEach((row) => {
			const date = row.cells[0].textContent;
			const fullDay = row.querySelector('[data-type="full"]').checked;
			const breakfast = row.querySelector('[data-type="breakfast"]').checked;
			const lunch = row.querySelector('[data-type="lunch"]').checked;
			const dinner = row.querySelector('[data-type="dinner"]').checked;
			const incidentals = row.querySelector(".incidentals-checkbox").checked;
			const dailyTotal = parseFloat(row.querySelector(".daily-total").textContent.replace("$", ""));

			rows.push([date, fullDay ? RATES.full : "0", breakfast ? RATES.breakfast : "0", lunch ? RATES.lunch : "0", dinner ? RATES.dinner : "0", incidentals ? RATES.incidentals : "0", dailyTotal.toFixed(2)]);

			grandTotal += dailyTotal;
		});

		// Add empty row and total
		rows.push([]);
		rows.push(["", "", "", "", "", "Total Per Diem:", grandTotal.toFixed(2)]);

		const csvContent = rows.map((row) => row.join(",")).join("\n");
		const blob = new Blob([csvContent], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("hidden", "");
		a.setAttribute("href", url);
		a.setAttribute("download", "per_diem_report.csv");
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	// Initialize the table
	generateDayRows();
}
